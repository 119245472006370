<template>
  <div class="green-border-box in-padd white-bg">
    <div class="row">

      <div class="col-xs-12 col-md-12 sort-control">
        <span style="float:left;">Total Stores : {{ cvsStores.length }}</span>
        <span class="filter-control">Filter By
           <select class="filters" v-model="available" @change="getCvsStores">
            <option value="">All</option>
            <option value="Yes" selected>Has store location</option>
            <option value="No" selected>No store location</option>
          </select>
        </span>
      </div>
    </div>
    <div class="row" v-if="cvsStores.length> 0">
      <div
        class="col-xs-12 col-sm-6 item-list"
        v-for="store in cvsStores"
        :key="store.id"
      >
        <CvsStoreItem :store="store"/>
      </div>
    </div>
    <h2
      v-if="cvsStores.length==0 && !loadingLocations"
      style="text-align:center;"
    >No Store Found in system!</h2>
    <h2 v-if="loadingLocations" style="text-align:center;">Loading Stores ...</h2>
    <div
      class="text-center"
      v-if="hasMoreLocations && !loadingLocations"
      @click="getStoreLocations"
    >
      <button class="btn-positive">Load more</button>
    </div>
  </div>
</template>
<script>
import CvsStoreItem from '@/components/SAdmin/CvsStoreItem'
export default {
  name: 'Stores',
  data () {
    return {
      timer: 0,
      available: '',
      loadingStores: true,
      hasMoreStores: false,
      hasMoreLocations: false,
      loadingLocations: true,
      editStoreFlag: false,
      storeSearchQuery: '',
      city: '',
      zipCode: '',
      state: '',
      sort: 'pickls_count-DESC',
      rowsOffset: 0,
      rowsLimitPerRequest: 100,
      storesLimitPerPage: 1000,
      storesPageOffset: 0,
      stores: [],
      cvsStores: [],
      locationDetails: {
        city: '',
        state: '',
        zip_code: '',
        longitude: '',
        latitude: '',
        image: '',
        address: '',
        store_id: ''
      },
      closeStoreEditPopup: false,
      storeId: '',
      stateId: '',
      states: []
    }
  },
  components: {
    CvsStoreItem
  },
  mounted () {
    this.getCvsStores()
  },
  methods: {
    getCvsStores () {
      this.loadingStores = true
      let q = {
        available: this.available
      }
      this.$http.get('cvs_stores', { params: q }).then(function (response) {
        this.cvsStores = response.body
        this.loadingLocations = false
      })
    }
  }
}
</script>

<style scoped>
.sort-control {
  text-align: right;
  padding: 6px 15px;
  margin-bottom: 20px;
}

.sort-control .btn-select {
  margin-bottom: 7px;
}

.filters {
  display: inline-block;
  width: auto;
  vertical-align: text-top;
}

@media only screen and (max-width: 991px) {
  .sort-control {
    text-align: left;
  }
}

@media only screen and (max-width: 767px) {
  .filter-control {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .filter-control {
    margin-right: 0;
    width: 100%;
    margin-bottom: 8px;
    display: inline-block;
  }

  .item-list >>> .outer-box span {
    white-space: normal;
    word-wrap: break-word;
  }
}
</style>
