var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "green-border-box in-padd white-bg" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-xs-12 col-md-12 sort-control" }, [
        _c("span", { staticStyle: { float: "left" } }, [
          _vm._v("Total Stores : " + _vm._s(_vm.cvsStores.length))
        ]),
        _c("span", { staticClass: "filter-control" }, [
          _vm._v("Filter By\n         "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.available,
                  expression: "available"
                }
              ],
              staticClass: "filters",
              on: {
                change: [
                  function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.available = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.getCvsStores
                ]
              }
            },
            [
              _c("option", { attrs: { value: "" } }, [_vm._v("All")]),
              _c("option", { attrs: { value: "Yes", selected: "" } }, [
                _vm._v("Has store location")
              ]),
              _c("option", { attrs: { value: "No", selected: "" } }, [
                _vm._v("No store location")
              ])
            ]
          )
        ])
      ])
    ]),
    _vm.cvsStores.length > 0
      ? _c(
          "div",
          { staticClass: "row" },
          _vm._l(_vm.cvsStores, function(store) {
            return _c(
              "div",
              { key: store.id, staticClass: "col-xs-12 col-sm-6 item-list" },
              [_c("CvsStoreItem", { attrs: { store: store } })],
              1
            )
          }),
          0
        )
      : _vm._e(),
    _vm.cvsStores.length == 0 && !_vm.loadingLocations
      ? _c("h2", { staticStyle: { "text-align": "center" } }, [
          _vm._v("No Store Found in system!")
        ])
      : _vm._e(),
    _vm.loadingLocations
      ? _c("h2", { staticStyle: { "text-align": "center" } }, [
          _vm._v("Loading Stores ...")
        ])
      : _vm._e(),
    _vm.hasMoreLocations && !_vm.loadingLocations
      ? _c(
          "div",
          { staticClass: "text-center", on: { click: _vm.getStoreLocations } },
          [_c("button", { staticClass: "btn-positive" }, [_vm._v("Load more")])]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }